import * as React from "react"
import withLayout from "../hoc/withLayout";
import { CityPageHeader } from "../components/CityPageHeader";
import BrestImg from "../images/gorod-brest-lg.jpg";
import BrestImgWebp from "../images/gorod-brest-lg.jpg.webp";
import BrestMapImg from "../images/gorod-brest-map.png";
import BrestMapImgWebp from "../images/gorod-brest-map.png.webp";

import IndexPrice from "../components/index/price/Price";
import { CityPageDelivery } from "../components/CityPageDelivery";
import HelpPhone from "../components/HelpPhone";
import Ecology from "../components/Ecology";
import Map from "../components/Map";
import { CityPageAboutUs } from "../components/CityPageAboutUs";



const Brest = () => {
  return (
    <>
      <CityPageHeader 
        header={<h1>Чистка сажевого фильтра в Бресте <small>и Брестской области</small></h1>} 
        jpg={BrestImg} 
        webp={BrestImgWebp} 
        alt="Чистка сажевого фильтра в Бресте и Брестской области"
      />

      <IndexPrice/>
      
      <CityPageDelivery 
        from="Бреста и Брестской области" 
        jpg={BrestMapImg}
        webp={BrestMapImgWebp}
        alt="Доставка сажевых фильтров в Брест и Брестскую область"
      />

      <HelpPhone/>
      <CityPageAboutUs/>
      <Ecology/>
      <Map/>
    </>
  )
}

export default withLayout(
  Brest, 
  "Чистка сажевых фильтров в Бресте",
  "Удаление 99% загрязнений возникающих из сажи, пепла, масла из двигателя и охлаждающей жидкости, без повреждения картриджа фильтра");
